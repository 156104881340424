/* eslint-disable react/prop-types */
import Button from 'react-components/src/components/button';

const ProviderButton = ({ disabled = false, providerName, className, onClick = () => {}, variant }) => (
  <Button disabled={disabled} onClick={onClick} className={className} variant={variant || undefined}>
    Log in {providerName ? ` with ${providerName}` : ''}
  </Button>
);

export default ProviderButton;
