/* eslint-disable */
import { useState, useCallback } from 'react';
import { LayoutLoginContentBox } from 'react-components';
import Button from 'react-components/src/components/button';
import Input from 'react-components/src/components/textfield';

const PasswordForm = ({ email, loginWithPassword, onBack, errorMessage = null }) => {
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    await loginWithPassword(email, password);
    setLoading(false);
  }, [email, password, loginWithPassword]);

  return (
    <LayoutLoginContentBox
      title={email ? `Enter the password for ${email}` : 'Please enter your password'}
      onBack={onBack}
    >
      <Input
        required
        placeholder="Enter your password"
        onChange={(e) => setPassword(e.target.value)}
        value={password}
        type="password"
      />
      <Button disabled={password.length < 5 || loading} onClick={handleSubmit}>
        Log in
      </Button>

      {errorMessage}
    </LayoutLoginContentBox>
  );
};

export default PasswordForm;
