/* eslint-disable react/prop-types */
import { useState, useCallback, useMemo, useEffect } from 'react';
import { Message, LayoutLoginContainer } from 'react-components';

import Button from 'react-components/src/components/button';
import LoginForm from './auth/LoginForm';
import VerificationForm from './auth/VerificationForm';

import LoadingSpinnerIcon from 'react-components/src/components/loading-spinner-icon';
import PasswordForm from './auth/PasswordForm';
import { getAuthConnectionsByEmailMutation } from '../mutations/auth/realm';
import {
  loginWithPasswordMutation,
  sendVerificationCodeMutation,
  loginWithCodeMutation,
  loginWithIdp,
} from '../mutations/auth/cognito';

const LOGIN = 0;
const VERIFICATION = 1;
const PASSWORD = 2;
const LOADING = 3;

const { MessageTypes } = Message;

const ERRORS = {
  sendVerificationCode:
    'An error occurred when trying to send the verification code, please verify your email and try again.',
  getAuthConnectionsByEmail:
    'An error occurred when fetching the login providers, please verify your email and try again.',
  loginWithPassword: 'Your credentials are incorrect. Please verify them and try again.',
  accountDoesNotExist: 'An account with your email was not found. Please verify your email and try again.',
};

const Auth = () => {
  const [userEmail, setUserEmail] = useState('');
  const [status, setStatus] = useState(LOADING);
  const [session, setSession] = useState({});
  const [errorMessage, setError] = useState(null);
  const [connections, setConnections] = useState([]);
  const [defaultConnection, setDefaultConnection] = useState(null);

  const sendVerificationCode = useCallback(async (email) => {
    try {
      setError(null);
      setSession(await sendVerificationCodeMutation(email));
      setStatus(VERIFICATION);
    } catch (e) {
      setError(ERRORS.sendVerificationCode);
    }
  }, []);

  const loginWithCode = useCallback(
    async (email, code) => {
      try {
        setError(null);
        await loginWithCodeMutation(session, code);
      } catch (e) {
        setError(
          <>
            Oops! It looks like you entered the wrong code, please try again or
            <Button
              onClick={sendVerificationCode}
              className="text-sm underline leading-6 mx-1 important"
              color="currentColor"
              data={email}
              variant="link"
            >
              click here
            </Button>
            to resend the code
          </>
        );
        throw e;
      }
    },
    [sendVerificationCode, session]
  );

  const getAuthConnectionsByEmail = useCallback(async (email) => {
    try {
      setError(null);
      const groupedConnections = await getAuthConnectionsByEmailMutation(email);
      setConnections(groupedConnections.others);
      setDefaultConnection(groupedConnections.default || null);
    } catch (e) {
      setError(ERRORS.getAuthConnectionsByEmail);
    }
  }, []);

  const loginWithPassword = useCallback(async (email, password) => {
    try {
      setError(null);
      await loginWithPasswordMutation(email, password);
    } catch (e) {
      setError(ERRORS.loginWithPassword);
    }
  }, []);

  const onContinue = useCallback(
    async (email, connection) => {
      setStatus(LOADING);
      setUserEmail(email);

      if (['Username-Password-Connection', 'Username-Password-Authentication'].includes(connection)) {
        setStatus(PASSWORD);
        return;
      }
      if (!connection || connection === 'email') {
        sendVerificationCode(email);
      } else {
        loginWithIdp(connection);
      }
    },
    [sendVerificationCode]
  );

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get('idp') !== null) {
      loginWithIdp(params.get('idp'));
      return;
    }
    setStatus(LOGIN);
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const errorParam = params.get('error');
    if (errorParam) {
      setError(errorParam);
    }
  }, []);

  const onBack = useCallback(() => {
    setError(null);
    setSession({});
    setStatus(LOGIN);
  }, []);

  const errorContainer = useMemo(() => {
    if (!errorMessage) {
      return null;
    }

    return <Message type={MessageTypes.ERROR}>{errorMessage}</Message>;
  }, [errorMessage]);

  let form = (
    <LoginForm
      errorMessage={errorContainer}
      defaultEmail={userEmail}
      onContinue={onContinue}
      connections={connections}
      defaultConnection={defaultConnection}
      getAuthConnectionsByEmail={getAuthConnectionsByEmail}
    />
  );

  if (status === LOADING) {
    form = (
      <div>
        <LoadingSpinnerIcon />
      </div>
    );
  }

  if (status === VERIFICATION) {
    form = (
      <VerificationForm
        email={userEmail}
        loginWithCode={loginWithCode}
        onResend={sendVerificationCode}
        onBack={onBack}
        errorMessage={errorContainer}
      />
    );
  }

  if (status === PASSWORD) {
    form = (
      <PasswordForm
        email={userEmail}
        loginWithPassword={loginWithPassword}
        onBack={onBack}
        errorMessage={errorContainer}
      />
    );
  }

  return <LayoutLoginContainer>{form}</LayoutLoginContainer>;
};

export default Auth;
