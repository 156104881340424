/* eslint-disable */
import { debounce } from 'utils/src/core-utils';
import { useState, useCallback, useMemo } from 'react';
import { Separator, LayoutLoginContentBox } from 'react-components';
import Input from 'react-components/src/components/textfield';
import ProviderButton from '../components/ProviderButton';
import Text from 'react-components/src/components/text';

// The optional ### suffix will force email passwordless as an escape hatch when SSO is misbehaving
const mailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+([#]{3})?$/;

const LoginForm = ({
  onContinue = () => {},
  defaultEmail,
  errorMessage,
  connections = [],
  defaultConnection = {},
  getAuthConnectionsByEmail = () => {},
}) => {
  const [email, setEmail] = useState(defaultEmail || '');
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (descriptor) => {
      if (email.match(mailPattern)) {
        setLoading(true);
        await onContinue(email, descriptor);
        setLoading(false);
      }
    },
    [email, onContinue]
  );

  const isValidEmail = useMemo(() => {
    return email.match(mailPattern);
  }, [email]);

  const requestEmail = useCallback(
    async (value) => {
      if (value && value.match(mailPattern)) {
        await getAuthConnectionsByEmail(value);
      }
      setLoading(false);
    },
    [getAuthConnectionsByEmail]
  );

  const requestEmailDebounced = useMemo(() => debounce(requestEmail, 500), [requestEmail]);

  const handleChange = useCallback(
    (event) => {
      const { value } = event.currentTarget;
      if (!loading) {
        setLoading(true);
      }
      setEmail(value);
      requestEmailDebounced(value);
    },
    [requestEmailDebounced, loading]
  );

  const AdditionalProviderButtons = useCallback(() => {
    if (!connections.length) {
      return null;
    }

    return (
      <>
        <Separator text="OR" className="mt-0" />
        {connections.map((provider) => (
          <ProviderButton
            key={provider.name}
            disabled={loading || !isValidEmail}
            providerName={provider.name}
            onClick={() => handleSubmit(provider.descriptor)}
            variant="secondary"
          />
        ))}
      </>
    );
  }, [connections, handleSubmit, loading, isValidEmail]);

  return (
    <LayoutLoginContentBox title="Sign in with your work email">
      <Input required placeholder="Enter your email address" onChange={handleChange} value={email} type="email" />

      <ProviderButton
        disabled={!isValidEmail || loading}
        onClick={() => handleSubmit(defaultConnection?.descriptor || '')}
        providerName={defaultConnection?.name || 'Email Code'}
      />

      <AdditionalProviderButtons />

      {errorMessage}

      <div className="pt-6">
        <Text type="body" size="sm" className="text-grayscale-64">
          By signing in, you agree to our{' '}
          <a
            className="text-indigo-100 focus:text-pink-100 hover:text-pink-100 underline"
            href="https://www.getbenepass.com/terms-of-service"
            target="_blank"
            rel="noopener noreferrer"
          >
            terms of service
          </a>{' '}
          and{' '}
          <a
            className="text-indigo-100 focus:text-pink-100 hover:text-pink-100 underline"
            href="https://www.getbenepass.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            privacy policy
          </a>
          .
        </Text>
      </div>
    </LayoutLoginContentBox>
  );
};

export default LoginForm;
