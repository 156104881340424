import ReactNotification from 'react-notifications-component';

import Auth from './ui/Auth';

const App = () => (
  <>
    <ReactNotification />
    <Auth />
  </>
);

export default App;
