/* eslint-disable react/prop-types */
import Input from 'react-otp-input';

const OTPInput = ({ value, onChange }) => {
  return (
    <Input
      value={value}
      onChange={onChange}
      numInputs={6}
      separator={null}
      isInputNum
      shouldAutoFocus
      focusStyle="focus:outline-none focus:ring focus:ring-indigo-100"
      containerStyle="w-full grid grid-cols-6 md:h-16 h-12 gap-2 md:gap-4"
      inputStyle={`
        text-input-color text-center font-semibold bg-input-bg-color
        text-2xl md:text-4xl rounded-xl md:rounded-2xl
        w-full h-full`}
    />
  );
};

export default OTPInput;
