import {
  CognitoIdentityProviderClient,
  InitiateAuthCommand,
  RespondToAuthChallengeCommand,
  SignUpCommand,
} from '@aws-sdk/client-cognito-identity-provider';
import config from '../../config';
import { omitBy } from 'utils/src/core-utils';

const cognitoClient = new CognitoIdentityProviderClient({ region: 'us-east-1' });

const isNull = (value) => value === null;

const getParametersFromURL = () => {
  const query = new URLSearchParams(window.location.search);
  const clientName = query.get('client') || config.defaultClient;
  const clientConfig = config.clients[clientName] || config.clients[config.defaultClient];
  const parameters = omitBy({ state: query.get('state'), code_challenge: query.get('code_challenge') }, isNull);
  return [clientConfig, parameters];
};

const [currentClient, parameters] = getParametersFromURL();

const completeCustomAuthFlow = ({ AuthenticationResult }) => {
  // For custom auth flows, we can't use a code exchange, so pass the tokens to the client in a URL fragment to avoid leakage
  // the client should immediately store these and then clear the URL fragment with history.replaceState

  const { redirect_uri } = currentClient;
  const parsedUrl = new URL(redirect_uri);
  const { state } = parameters;
  if (state) {
    parsedUrl.searchParams.append('state', state);
  }
  const payload = JSON.stringify(AuthenticationResult);
  window.location = `${parsedUrl.toString()}#/authentication_result=${payload}`;
};

const respondToCustomAuthFlowChallenge = async ({ ChallengeParameters, Session, ANSWER }) => {
  const command = new RespondToAuthChallengeCommand({
    ChallengeName: 'CUSTOM_CHALLENGE',
    ChallengeResponses: {
      ...ChallengeParameters,
      ANSWER,
    },
    ClientId: currentClient.client_id,
    Session,
  });
  return cognitoClient.send(command);
};

export const loginWithCodeMutation = async (session, code) => {
  completeCustomAuthFlow(await respondToCustomAuthFlowChallenge({ ...session, ANSWER: code }));
};

const initiateCustomAuthFlow = async (USERNAME) => {
  const command = new InitiateAuthCommand({
    AuthFlow: 'CUSTOM_AUTH',
    ClientId: currentClient.client_id,
    AuthParameters: {
      USERNAME,
    },
  });
  return cognitoClient.send(command);
};

const getRandomString = (lengthInBytes) => {
  const randomValues = new Uint8Array(lengthInBytes);
  window.crypto.getRandomValues(randomValues);
  return window.btoa(randomValues);
};

const createEmailPasswordlessUser = async (email) => {
  const command = new SignUpCommand({
    ClientId: currentClient.client_id,
    Username: email,
    Password: `+Aa1${getRandomString(32)}1aA+`,
    UserAttributes: [{ Name: 'email', Value: email }],
  });
  try {
    await cognitoClient.send(command);
  } catch (error) {
    const { name } = error;
    if (name !== 'UsernameExistsException') {
      // If the username already exists, then no harm, no foul
      throw error;
    }
  }
};

export const sendVerificationCodeMutation = async (emailAddress) => {
  // For email passwordless we auto-create the user if they don't already exist
  // see, https://aws.amazon.com/blogs/mobile/implementing-passwordless-email-authentication-with-amazon-cognito/
  const USERNAME = emailAddress.toLowerCase();
  await createEmailPasswordlessUser(USERNAME);
  const { ChallengeParameters, Session } = await initiateCustomAuthFlow(USERNAME);
  return { ChallengeParameters, Session };
};

export const loginWithPasswordMutation = async (emailAddress, password) => {
  // This is a special case that's only used for app store reviewers, so for that account
  // we hijack the normal email passwordless flow
  const USERNAME = emailAddress.toLowerCase();
  const ANSWER = password;
  const { ChallengeParameters, Session } = await initiateCustomAuthFlow(USERNAME);
  completeCustomAuthFlow(await respondToCustomAuthFlowChallenge({ ChallengeParameters, Session, ANSWER }));
};

export const loginWithIdp = (idpName) => {
  // Handle the legacy g-suite idp name so we don't break auth0 till we're ready to
  const identity_provider = idpName === 'google-oauth2' ? 'Google' : idpName;
  const { client_id, redirect_uri } = currentClient;
  const session_state = parameters.code_challenge ? { ...parameters, code_challenge_method: 'S256' } : parameters;
  const query = new URLSearchParams({
    response_type: 'code',
    identity_provider,
    client_id,
    redirect_uri,
    ...session_state,
  });
  window.location.href = `${config.authorizationEndpoint}?${query.toString()}`;
};
