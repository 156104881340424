import axios from 'axios';
import config from '../../config';
import { drop } from 'utils/src/core-utils';

// const params = {
//   overrides: {
//     __tenant: config?.auth0Tenant,
//     __token_issuer: config?.authorizationServer?.issuer,
//   },
//   domain: config?.auth0Domain,
//   clientID: config?.clientID,
//   redirectUri: config?.callbackURL,
//   responseType: RESPONSE_TYPE_NORMAL_LOGIN,
//   ...config?.internalOptions,
// };

export const getAuthConnectionsByEmailMutation = async (email) => {
  try {
    const response = await axios.post(config.benepassRealm, { email });
    const connections = response.data?.result ?? [];

    let defaultConnection = connections.find((current) => current.is_default);

    let othersConnections = connections.filter((current) => current.is_default === false);

    if (!defaultConnection) {
      [defaultConnection] = othersConnections;
      othersConnections = drop(othersConnections);
    }

    return { default: defaultConnection, others: othersConnections };
  } catch (e) {
    return { default: null, others: [] };
  }
};
