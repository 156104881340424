/* eslint-disable */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useMemo, useState } from 'react';
import OTPInput from '../components/OTPInput';
import { store } from 'react-notifications-component';
import { LayoutLoginContentBox } from 'react-components';
import LoadingSpinnerIcon from 'react-components/src/components/loading-spinner-icon';
import Text from 'react-components/src/components/text';

const VerificationForm = ({ email, loginWithCode, onResend, onBack, errorMessage }) => {
  const [code, setCode] = useState('');
  const [checking, setChecking] = useState(false);
  const parsedEmail = useMemo(() => {
    return email.replace(/\+.*@/, '@');
  }, [email]);

  const onChangeCode = async (value) => {
    setCode(value);
    if (value.length === 6) {
      setChecking(true);
      try {
        await loginWithCode(email, value);
      } catch (error) {
        setCode('');
        setChecking(false);
      }
    }
  };

  const onClickedResend = async () => {
    setChecking(true);
    setCode('');
    await onResend(email);
    setChecking(false);
    store.addNotification({
      message: `We’ve sent another code to ${email}, you should get it soon!`,
      type: 'success',
      container: 'top-center',
      dismiss: {
        duration: 3000,
      },
    });
  };

  return (
    <LayoutLoginContentBox title="Enter verification code" onBack={onBack}>
      <Text type="body" size="md" className="text-grayscale-64">
        Check <b>{parsedEmail}</b> for a verification code. Didn&apos;t receive it?&nbsp;
        <span
          className="text-indigo-100 hover:text-pink-100 underline font-semibold"
          role="button"
          tabIndex="0"
          onClick={onClickedResend}
        >
          Resend
        </span>
        .
      </Text>

      <div className="flex flex-col self-center gap-6">
        {checking ? (
          <LoadingSpinnerIcon />
        ) : (
          <div className="w-full max-w-[500px]">
            <OTPInput value={code} onChange={onChangeCode} />
          </div>
        )}
        {errorMessage}
      </div>
    </LayoutLoginContentBox>
  );
};

export default VerificationForm;
